.container {
  width: 170px;
  height: 120px;
  border-radius: 15px;
  box-shadow: 3px 3px 5px #d2d2d2;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 0 10px;
  z-index: 2;
  background: white;
  position: relative;
  cursor: pointer;
}
.container .up {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 10px;
}
.container .up .up_left {
  font-size: 30px;
  font-family: 'Source Han Sans SC';
  font-weight: bold;
  color: #161E66;
}
.container .up .up_right {
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  color: #161E66;
}
.container .down {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.container .down .down_left {
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: 500;
  color: #161E66;
}
.container .down .down_right {
  font-size: 50px;
  font-family: 'Roboto';
  font-weight: bold;
  color: #161E66;
  line-height: 40px;
}
.click_show {
  position: absolute;
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: 500;
  color: #FFFFFF;
  padding: 0 20px;
  background: #A12107;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 20px;
  top: 0px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
}
.enter {
  transition: all 0.5s;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}
.leave {
  transition: all 0.5s;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
.click_show_enter {
  transition: all 0.5s;
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
}
.click_show_leave {
  transition: all 0.5s;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
