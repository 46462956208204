.container {
  width: 570px;
  height: 320px;
  margin: 0 auto;
  padding-top: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.item_container {
  width: 275px;
  height: 100%;
  border-radius: 10px;
}
.item_container .angle_container {
  box-sizing: border-box;
  padding: 0 20px;
  height: 20px;
  display: -webkit-flex;
  display: flex;
}
.item_container .angle_container .item_angle {
  height: 0px;
  width: 0px;
  border-bottom: 10px solid #121851;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.item_container .bg {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #121851;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
}
.item_container .bg .title_container {
  width: 100%;
  height: 60px;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
.item_container .bg .title_container .title_left {
  font-size: 16px;
  font-family: 'Source Han Sans SC';
  font-weight: 500;
  color: #FFFFFF;
  line-height: 24px;
}
.item_container .bg .title_container .title_right {
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #D0D1DC;
  line-height: 14px;
  text-align: right;
}
.item_container .bg .content {
  background: white;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 220px;
  padding: 10px;
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #121851;
  line-height: 19px;
}
