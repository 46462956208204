.container {
  width: 70%;
  height: 400px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
}
.container .public_container {
  width: 50%;
  height: 100%;
}
.container .left_container {
  width: 50%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.container .left_container .img_container {
  width: 300px;
  height: 300px;
  background-image: linear-gradient(to right, #141B61, #AE280D);
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.container .left_container .img_container .img {
  width: 90%;
  height: 90%;
  border-radius: 50%;
}
.container .right_container {
  width: 50%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
}
.container .right_container .item_container {
  width: 50%;
  height: 40px;
  display: -webkit-flex;
  display: flex;
}
.container .right_container .item_container .item_point {
  width: 10px;
  height: 10px;
  background: #141B61;
  border-radius: 50%;
  margin-top: 8px;
}
.container .right_container .item_container .item_title_content_container {
  box-sizing: border-box;
  padding-left: 20px;
}
.container .right_container .item_container .item_title_content_container .item_title {
  font-size: 18px;
  font-family: 'Source Han Sans SC';
  font-weight: 500;
  color: #121851;
}
.container .right_container .item_container .item_title_content_container .item_content {
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: 500;
  color: #121851;
  line-height: 26px;
}
