@import 'animate.css';
.menu_mask {
  width: 100%;
  height: 100%;
  background: rgba(58, 58, 58, 0.363);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  display: none;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.menu_mask .menu_item_container {
  width: 50%;
  height: 100%;
  background: white;
  position: relative;
}
.menu_mask .menu_item_container .hide_mask_img {
  height: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
}
.menu_mask .menu_item_container .menu_item {
  height: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: border-box;
  padding-left: 8px;
  font-family: 'Source Han Sans SC';
  font-size: 16px;
}
.menu_mask .menu_item_container .website_logo {
  position: absolute;
  right: 15px;
  bottom: 20px;
}
.nav {
  width: 100%;
  height: 68px;
  background: white;
}
.nav .up {
  height: 25px;
}
.nav .down {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 10px;
}
.nav .down .logo {
  width: 30%;
}
.nav .down .title {
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #161E66;
  text-align: left;
  white-space: nowrap;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  transition: all 0.5s;
  -webkit-flex: 1 1;
  flex: 1 1;
}
.nav .down .menu {
  width: 30px;
}
.banner {
  position: relative;
}
.banner .banner_img {
  width: 100%;
}
.banner .banner_text {
  font-size: 65px;
  font-family: 'Roboto';
  font-weight: 900;
  color: #FFFFFF;
  position: absolute;
  bottom: 0;
  left: 40px;
}
.explore_the_world_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0px auto;
  padding: 50px 0;
  width: 90%;
}
.explore_the_world_container .title {
  font-size: 20px;
  font-family: 'Roboto';
  font-weight: 900;
  color: #121851;
  line-height: 12px;
  opacity: 0.1;
  white-space: nowrap;
}
.explore_the_world_container .sub_title {
  font-size: 30px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  color: #161E66;
  white-space: nowrap;
}
.explore_the_world_container .content {
  font-size: 13px;
  font-family: 'Roboto';
  font-weight: 400;
  color: #161E66;
  line-height: 24px;
  text-align: justify;
  text-align-last: center;
  margin-top: 15px;
}
.form_container {
  width: 100%;
  height: 500px;
  background: url('https://parent.ponyrunning.com/activity/20210424/form_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.form_container .label {
  font-size: 14px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #121851;
  opacity: 0.2;
}
.form_container .input {
  border: none;
  width: 70%;
  height: 100%;
  font-size: 14px;
  font-family: 'Source Han Sans SC';
}
.form_container .public {
  width: 80%;
  height: 45px;
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 0 8px;
}
.form_container .son_name {
  width: 80%;
  height: 45px;
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 0 8px;
}
.form_container .son_birthday {
  width: 80%;
  height: 45px;
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 0 8px;
}
.form_container .son_grade {
  width: 80%;
  height: 45px;
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 0 8px;
  position: relative;
}
.form_container .son_grade .select {
  position: absolute;
  left: 0;
  top: 45px;
  width: 100%;
  background: white;
  z-index: 2;
  display: none;
}
.form_container .son_grade .select .active {
  background: #161E66;
  color: white;
}
.form_container .son_grade .select > div {
  border-bottom: 1px solid #ddd;
  height: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  font-family: 'Source Han Sans SC';
  font-size: 14px;
}
.form_container .father_phone {
  width: 80%;
  height: 45px;
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 0 8px;
}
.form_container .phone_code {
  width: 80%;
  height: 45px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 35px;
}
.form_container .phone_code .code {
  height: 100%;
  width: 70%;
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 8px;
  white-space: nowrap;
}
.form_container .phone_code .get_code {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  color: white;
  font-family: 'Source Han Sans SC';
  font-size: 12px;
  background: #121851;
  padding: 0 10px;
}
.form_container .sub {
  font-size: 21px;
  font-family: 'YouSheBiaoTiHei';
  font-weight: 400;
  color: #FFFFFF;
  width: 70%;
  height: 45px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  background: #121851;
  margin-bottom: 15px;
}
.form_container .address {
  width: 70%;
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #121851;
  line-height: 16px;
  text-align: center;
}
.course_item {
  width: 90%;
  height: 100px;
  margin: 0 auto;
  border-radius: 15px;
  box-shadow: #CCC5B8 0 0 15px -6px;
  background: white;
  display: -webkit-flex;
  display: flex;
  margin-top: 15px;
  position: relative;
}
.course_item .course_click_show {
  position: absolute;
  background: #A12107;
  border-radius: 12px 12px 0px 0px;
  height: 24px;
  top: -24px;
  right: 20px;
  padding: 0 8px;
  font-size: 10px;
  font-family: 'Source Han Sans SC';
  font-weight: 500;
  color: #FFFFFF;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.course_item .course_item_left {
  width: 70%;
  height: 100%;
  padding: 18px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.course_item .course_item_left .text {
  font-size: 14px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #721300;
}
.course_item .course_item_left .left_up_container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.course_item .course_item_left .left_up_container .title {
  font-size: 32px;
  font-family: 'Source Han Sans SC';
  font-weight: bold;
  color: #721300;
}
.course_item .course_item_left .left_up_container .content {
  margin-left: 30px;
  font-size: 10px;
  font-family: 'Roboto';
  font-weight: 400;
  color: #721300;
}
.course_item .course_item_right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 80px;
  width: 30%;
  height: 100%;
  color: #FFFFFF !important;
  text-shadow: 1px 1px #121851, -1px -1px #121851, 1px -1px #121851, -1px 1px #121851;
}
.slide {
  width: 90%;
  height: 420px;
  background: #121851;
  margin: 0 auto;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 50px 0;
  transition: all 0.5s;
}
.slide .slide_title {
  width: 90%;
  height: 33px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 0 auto;
}
.slide .slide_title .title_left {
  text-align: left;
  font-size: 14px;
  font-family: 'Source Han Sans SC';
  font-weight: 500;
  color: #FFFFFF;
  line-height: 20px;
}
.slide .slide_title .title_right {
  text-align: right;
  font-size: 10px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #D0D1DC;
  line-height: 15px;
}
.peiyang_container {
  width: 100%;
  height: 250px;
  position: relative;
  margin-top: 20px;
}
.peiyang_container .left_up {
  width: 60%;
  height: 80%;
  background: #121851;
  position: absolute;
  left: 0;
  top: 0;
}
.peiyang_container .peiyang_img {
  width: 90%;
  height: 85%;
  position: absolute;
  right: 0;
  bottom: 0;
}
.peiyang_title_content {
  margin: 50px auto 0;
  width: 80%;
}
.peiyang_title_content .peiyang_title {
  font-size: 14px;
  font-family: 'Source Han Sans SC';
  font-weight: bold;
  color: #121851;
}
.peiyang_title_content .peiyang_content {
  margin-top: 10px;
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #121851;
  line-height: 17px;
}
.call {
  width: 100%;
  height: 250px;
  background: url('https://parent.ponyrunning.com/activity/20210424/call.png');
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 80px;
}
.call .call_title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.call .call_title .call_up {
  font-size: 31px;
  font-family: 'YouSheBiaoTiHei';
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 40px;
}
.call .call_title .call_down {
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: bold;
  color: #FFFFFF;
  margin-top: 12px;
}
.call .submit {
  height: 40px;
  margin-top: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.team {
  width: 100%;
  margin: 30px 0;
}
.team .gradients {
  width: 270px;
  height: 270px;
  border-radius: 50%;
  background-image: linear-gradient(to right, #141B61, #AE280D);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transform: translateX(-30px);
  transform: translateX(-30px);
}
.team .gradients img {
  border-radius: 50%;
  width: 90%;
  height: 90%;
}
.team_five {
  width: 100%;
  height: 300px;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  padding: 0 10px;
}
.team_five .item_height {
  height: 33.33333333%;
  display: -webkit-flex;
  display: flex;
}
.team_five .item_style {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #121851;
  margin: 8px 10px 0 0;
}
.team_five .item_text_style {
  font-size: 14px;
  font-family: 'Source Han Sans SC';
  font-weight: 500;
  color: #121851;
  line-height: 20px;
}
.team_five .item_english {
  font-size: 12px;
}
.team_five .team_five_left {
  width: 50%;
  height: 100%;
}
.team_five .team_five_left .team_item {
  height: 33.33333333%;
  display: -webkit-flex;
  display: flex;
}
.team_five .team_five_left .team_item .team_item_pont {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #121851;
  margin: 8px 10px 0 0;
}
.team_five .team_five_left .team_item .team_item_text {
  font-size: 14px;
  font-family: 'Source Han Sans SC';
  font-weight: 500;
  color: #121851;
  line-height: 20px;
}
.team_five .team_five_left .team_item .team_item_text .team_english {
  font-size: 12px;
}
.team_five .team_five_right {
  width: 50%;
  height: 100%;
}
.team_five .team_five_right .team_item {
  height: 33.33333333%;
  display: -webkit-flex;
  display: flex;
}
.team_five .team_five_right .team_item .team_item_pont {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #121851;
  margin: 8px 10px 0 0;
}
.team_five .team_five_right .team_item .team_item_text {
  font-size: 14px;
  font-family: 'Source Han Sans SC';
  font-weight: 500;
  color: #121851;
  line-height: 20px;
}
.team_five .team_five_right .team_item .team_item_text .team_english {
  font-size: 12px;
}
.team_show_container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  background: url('https://parent.ponyrunning.com/activity/20210424/team_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.team_show_container .team_show {
  width: 80%;
  margin: 30px 0;
}
.footer_item_container {
  width: 85%;
  height: 90px;
  margin: 45px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
.footer_item_container .footer_img_container {
  width: 80px;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.footer_item_container .footer_text_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex: 0.9 1;
  flex: 0.9 1;
}
.footer_item_container .footer_title {
  font-size: 16px;
  font-family: 'Source Han Sans SC';
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 2px solid #121851;
  margin-bottom: 3px;
}
.footer_item_container .footer_text {
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #121851;
  text-align: justify;
  line-height: 16px;
}
html {
  background: #faf9f7;
}
.swiper_text {
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #121851;
  line-height: 22px;
}
.select_style {
  border: none;
  width: 70%;
  height: 100%;
  background: white;
  font-size: 14px;
  font-family: 'Source Han Sans SC';
}
.select_style option {
  height: 50px;
}
.fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 65px;
  background: #121851;
  color: white;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 20px;
  font-family: 'Source Han Sans SC';
}
