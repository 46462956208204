.banner_bg {
  background: url('https://parent.ponyrunning.com/activity/20210424/banner_pc.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
}
.banner_bg .logo {
  width: 15%;
  margin-top: 20px;
}
.banner_bg .title {
  margin-top: 20px;
  font-size: 16px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #FFFFFF;
}
.banner_bg .englash_title {
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #FFFFFF;
}
.banner_bg .explore {
  font-size: 80px;
  font-family: 'Roboto';
  font-weight: 900;
  color: #FFFFFF;
  white-space: nowrap;
  margin-top: 180px;
}
