.container {
  width: 80%;
  height: 500px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
}
.container .left {
  width: 60%;
  height: 100%;
  position: relative;
}
.container .left .bg {
  width: 50%;
  height: 80%;
  background: #121851;
}
.container .left .img {
  width: 80%;
  height: 70%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: url('https://parent.ponyrunning.com/activity/20210424/peiyang.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.container .left .item {
  width: 40%;
  height: 70px;
  position: absolute;
  top: 0;
  right: 0;
}
.container .left .item .title {
  font-size: 18px;
  font-family: 'Source Han Sans SC';
  font-weight: bold;
  color: #121851;
  line-height: 24px;
}
.container .left .item .content {
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  color: #121851;
  line-height: 20px;
}
.container .right {
  width: 40%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 100px;
}
.container .right .item .title {
  font-size: 18px;
  font-family: 'Source Han Sans SC';
  font-weight: bold;
  color: #121851;
  line-height: 24px;
}
.container .right .item .content {
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  color: #121851;
  line-height: 20px;
}
