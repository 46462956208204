.container {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  width: 50%;
  margin: 0 auto;
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-items: center;
  -webkit-align-items: center;
  align-items: center;
}
.container .english {
  font-size: 42px;
  font-family: 'Roboto';
  font-weight: 900;
  color: #121851;
  opacity: 0.1;
  white-space: nowrap;
}
.container .title {
  font-size: 42px;
  font-family: 'Microsoft YaHei';
  font-weight: bold;
  color: #161E66;
  white-space: nowrap;
}
.container .content {
  margin-top: 10px;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 400;
  color: #161E66;
  text-align: center;
  line-height: 30px;
}
