.container {
  background: #121851;
  padding-bottom: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.container .img_container {
  -webkit-flex: 1 1;
  flex: 1 1;
  background: url('https://parent.ponyrunning.com/activity/20210424/team_bg_pc.png');
  background-repeat: no-repeat;
  background-position: 65%;
  background-size: 200% 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
