.swiper_container {
  width: 90%;
  margin: 30px auto;
  background: white;
  border-radius: 16px;
  height: 250px;
  box-sizing: border-box;
  position: relative;
}
.slide_title {
  width: 90%;
  height: 33px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 0 auto;
}
.title_left {
  text-align: left;
  font-size: 14px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 20px;
}
.title_right {
  text-align: right;
  font-size: 10px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #D0D1DC;
  line-height: 15px;
}
