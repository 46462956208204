.container {
  background: url('https://parent.ponyrunning.com/activity/20210424/division.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100px;
}
.container .text_img_container {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 430px;
  margin: 0 auto;
  -webkit-transform: translateX(-100px);
  transform: translateX(-100px);
}
.container .text_img_container .title {
  font-size: 21px;
  font-family: 'YouSheBiaoTiHei';
  font-weight: 400;
  color: #FFFFFF;
}
.container .text_img_container .content {
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: bold;
  color: #FFFFFF;
  line-height: 26px;
}
.container .text_img_container .img {
  width: 115px;
  cursor: pointer;
}
