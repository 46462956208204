.form_bg {
  background: url('https://parent.ponyrunning.com/activity/20210424/form_pc.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
  display: -webkit-flex;
  display: flex;
  justify-items: center;
  -webkit-align-items: center;
  align-items: center;
}
.form_bg .form_container {
  width: 40%;
  height: 400px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-items: space-between;
}
.form_bg .form_container .form_item {
  width: 100%;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.form_bg .form_container .form_item .label {
  font-size: 18px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #121851;
  text-align: right;
  width: 80px;
}
.form_bg .form_container .form_item .input_container {
  -webkit-flex: 1 1;
  flex: 1 1;
  padding-left: 10px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
}
.form_bg .form_container .form_item .input_container .input {
  box-sizing: border-box;
  background: white;
  padding-left: 10px;
  border: none;
  height: 40px;
  width: 100%;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  font-size: 16px;
}
.form_bg .form_container .form_item .input_container .get_code {
  height: 40px;
  color: white;
  font-size: 16px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
}
.form_bg .form_container .form_item .sub {
  font-size: 24px;
  font-family: 'YouSheBiaoTiHei';
  font-weight: 400;
  color: #FFFFFF;
  height: 50px;
  background: #121851;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
}
.form_bg .form_container .form_address {
  text-align: center;
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #121851;
  white-space: nowrap;
}
