.container {
  position: absolute;
}
.container .title_content_container .title {
  font-size: 16px;
  font-family: 'Source Han Sans SC';
  font-weight: 500;
  color: #121851;
  border-bottom: 2px solid #121851;
  box-sizing: border-box;
  padding-bottom: 5px;
  white-space: nowrap;
  margin-left: 10px;
}
.container .title_content_container .content {
  font-size: 12px;
  font-family: 'Source Han Sans SC';
  font-weight: 400;
  color: #121851;
  box-sizing: border-box;
  padding-top: 10px;
  margin-left: 10px;
}
.img_left_right {
  width: 100px;
}
.img_up_down {
  width: 150px;
}
.left_right {
  width: 350px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.up_down {
  width: 180px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
}
.footer {
  position: relative;
  width: 800px;
  height: 500px;
  margin: 0 auto;
}
.footer .footer_point {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  left: 60%;
}
